var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "textHolder" }, [
    _vm.inputType === "checkbox" && _vm.useInputInsteadOfTextArea
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.text,
              expression: "text",
            },
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.brokenTooltip,
              expression: "brokenTooltip",
            },
          ],
          staticClass: "text",
          class: {
            broken: _vm.isForbidden,
            disabled: _vm.disabled,
            smaller: _vm.smaller,
          },
          style: {
            minHeight: _vm.minHeight + "px",
            maxHeight: _vm.maxHeight + "px",
          },
          attrs: {
            placeholder: _vm.placeholder,
            disabled: _vm.disabled,
            min: _vm.minValue,
            max: _vm.maxValue,
            step: _vm.step,
            type: "checkbox",
          },
          domProps: {
            checked: Array.isArray(_vm.text)
              ? _vm._i(_vm.text, null) > -1
              : _vm.text,
          },
          on: {
            keyup: [
              function ($event) {
                return _vm.keyPressed(true)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.keyPressed(false)
              },
            ],
            change: [
              function ($event) {
                var $$a = _vm.text,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.text = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.text = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.text = $$c
                }
              },
              function ($event) {
                return _vm.keyPressed(true)
              },
            ],
          },
        })
      : _vm.inputType === "radio" && _vm.useInputInsteadOfTextArea
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.text,
              expression: "text",
            },
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.brokenTooltip,
              expression: "brokenTooltip",
            },
          ],
          staticClass: "text",
          class: {
            broken: _vm.isForbidden,
            disabled: _vm.disabled,
            smaller: _vm.smaller,
          },
          style: {
            minHeight: _vm.minHeight + "px",
            maxHeight: _vm.maxHeight + "px",
          },
          attrs: {
            placeholder: _vm.placeholder,
            disabled: _vm.disabled,
            min: _vm.minValue,
            max: _vm.maxValue,
            step: _vm.step,
            type: "radio",
          },
          domProps: { checked: _vm._q(_vm.text, null) },
          on: {
            keyup: [
              function ($event) {
                return _vm.keyPressed(true)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.keyPressed(false)
              },
            ],
            change: [
              function ($event) {
                _vm.text = null
              },
              function ($event) {
                return _vm.keyPressed(true)
              },
            ],
          },
        })
      : _vm.useInputInsteadOfTextArea
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.text,
              expression: "text",
            },
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.brokenTooltip,
              expression: "brokenTooltip",
            },
          ],
          staticClass: "text",
          class: {
            broken: _vm.isForbidden,
            disabled: _vm.disabled,
            smaller: _vm.smaller,
          },
          style: {
            minHeight: _vm.minHeight + "px",
            maxHeight: _vm.maxHeight + "px",
          },
          attrs: {
            placeholder: _vm.placeholder,
            disabled: _vm.disabled,
            min: _vm.minValue,
            max: _vm.maxValue,
            step: _vm.step,
            type: _vm.inputType,
          },
          domProps: { value: _vm.text },
          on: {
            keyup: [
              function ($event) {
                return _vm.keyPressed(true)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.keyPressed(false)
              },
            ],
            change: function ($event) {
              return _vm.keyPressed(true)
            },
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.text = $event.target.value
            },
          },
        })
      : _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.text,
              expression: "text",
            },
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.brokenTooltip,
              expression: "brokenTooltip",
            },
          ],
          staticClass: "text",
          class: {
            broken: _vm.isForbidden,
            disabled: _vm.disabled,
            smaller: _vm.smaller,
          },
          style: {
            minHeight: _vm.minHeight + "px",
            maxHeight: _vm.maxHeight + "px",
          },
          attrs: { placeholder: _vm.placeholder, disabled: _vm.disabled },
          domProps: { value: _vm.text },
          on: {
            keyup: [
              function ($event) {
                return _vm.keyPressed(true)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.keyPressed(false)
              },
            ],
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.text = $event.target.value
            },
          },
        }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
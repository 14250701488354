<template>
  <div class="textHolder">
    <input
      v-if="useInputInsteadOfTextArea"
      :style="{ minHeight: `${minHeight}px`, maxHeight: `${maxHeight}px` }"
      :placeholder="placeholder"
      :disabled="disabled"
      v-model="text"
      class="text"
      :class="{ broken: isForbidden, disabled: disabled, smaller: smaller }"
      v-tooltip="brokenTooltip"
      :type="inputType"
      :min="minValue"
      :max="maxValue"
      :step="step"
      @keyup="keyPressed(true)"
      @keyup.enter="keyPressed(false)"
      @change="keyPressed(true)"
    />
    <textarea
      v-else
      :style="{ minHeight: `${minHeight}px`, maxHeight: `${maxHeight}px` }"
      v-model="text"
      class="text"
      :class="{ broken: isForbidden, disabled: disabled, smaller: smaller }"
      v-tooltip="brokenTooltip"
      :placeholder="placeholder"
      :disabled="disabled"
      @keyup="keyPressed(true)"
      @keyup.enter="keyPressed(false)"
    />
  </div>
</template>

<script>

const forbiddenChars = ['<', '>'];

export default {
    props: {
        listOfExcludedStrings: {
            type: Array,
            required: false,
            default: () => [],
        },
        startingVal: {
            type: String,
            required: false,
            default: '',
        },
        submitOnEnterOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
        useInputInsteadOfTextArea: {
            type: Boolean,
            required: false,
            default: false,
        },
        inputType: {
            type: String,
            required: false,
            default: 'text',
        },
        smaller: {
            type: Boolean,
            required: false,
            default: false,
        },
        minHeight: {
            type: Number,
            required: false,
            default: null,
        },
        maxHeight: {
            type: Number,
            required: false,
            default: null,
        },
        allowBlank: {
            type: Boolean,
            required: false,
            default: false,
        },
        showTooltipError: {
            type: Boolean,
            required: false,
            default: true,
        },
        tooltipPosition: {
            type: String,
            required: false,
            default: 'bottom',
        },
        placeholder: {
            type: String,
            required: false,
            default: '',
        },
        doNotEmitOnConstructor: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        minValue: {
            type: Number,
            required: false,
            default: null,
        },
        maxValue: {
            type: Number,
            required: false,
            default: null,
        },
        step: {
            type: Number,
            required: false,
            default: null,
        },
        doCalculatorCheck: {
            type: Boolean,
            required: false,
            default: false,
        },
        validExpression: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data () {
        return {
            text: '',
            brokenTooltip: {
                content: '',
                show: false,
                trigger: 'manual',
                placement: this.tooltipPosition,
            },
            isForbidden: true,
            originalStartingVal: '',
        };
    },
    created () {
        this.text = this.inputType === 'number' ? Number.parseInt(this.startingVal) : this.startingVal;
        this.originalStartingVal = this.text;
        if (!this.doNotEmitOnConstructor) this.keyPressed();
        else this.isForbidden = false;
    },
    methods: {
        keyPressed (keyup, emit = true) {
            const forbiddenBlank = !this.allowBlank && (this.text == null || this.text.length === 0);
            const forbiddenValue = Object.keys(this.listOfExcludedStrings).length > 0 && this.text != this.originalStartingVal
            && this.listOfExcludedStrings.includes(this.text);
            const forbiddenExpression = this.doCalculatorCheck && !this.validExpression;

            this.isForbidden = this.showTooltipError && (forbiddenBlank || forbiddenValue || forbiddenExpression
                || forbiddenChars.some(char => {
                    if (this.text.includes(char)) {
                        return true;
                    }
                    return false;
                }));

            if (emit && !(keyup && this.submitOnEnterOnly)) {
                this.$emit('textChange', {
                    content: this.text,
                    broken: this.isForbidden,
                });
            }

            if (forbiddenBlank) {
                this.brokenTooltip.content = 'This field cannot be left blank';
            } else if (forbiddenExpression) {
                this.brokenTooltip.content = 'This is not a valid expression';
            } else if (forbiddenValue) {
                this.brokenTooltip.content = 'An item with identical name already exists';
            } else if (this.isForbidden) {
                this.brokenTooltip.content = 'The following characters are not allowed: ';
                forbiddenChars.forEach((char, index) => {
                    this.brokenTooltip.content += `'${char}'${index !== forbiddenChars.length - 1 ? ', ' : ''}`;
                });
            }

            this.brokenTooltip.show = this.isForbidden;
        },
    },
    computed: {

    },
    watch: {
        startingVal () {
            this.text = this.startingVal;
            this.keyPressed(false, false);
        },
        showTooltipError () {
            this.keyPressed();
        },
        validExpression () {
            this.keyPressed();
        },
    },
};
</script>

<style lang="scss" scoped>
.textHolder {
    width: 100%;
    .text {
        background: #fff;
        border-radius: 5px;
        border: 1px solid #e8e8e8;
        display: block;
        font-size: 14px;
        margin-bottom: 10px;
        padding: 8px;
        width: 100%;
        font-family: Arial;
        &.smaller {
            padding: 5px;
        }
        &.broken {
            background: $ALTERNATIVE1TINT2;
        }
        &.disabled {
            background: $iconGrey;
        }
    }
}
</style>
